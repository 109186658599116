import * as React from "react";
import { useEffect } from "react";
import { graphql } from "gatsby";
import SectionResultsMapComponent from "components/section-results-map/section-results-map.component";
import { buildSections } from "../../services/sections";
import {Seo} from "components/seo/seo";

const Departement = ({ data, pageContext}) => {
  const pageData = data.cms.pages.data[0].attributes;
  const sections = pageData.sections;
  const storeLocator = data.cms.storeLocator.data.attributes;
  const departement = data.cms.departements.data[0].attributes;
  const breadcrumbPath = [
      {title:'Localiser une agence', url:'/'},
      {title:departement.region, url: '/'+departement.slugRegion},
      {title:departement.nom},
  ];
  useEffect(() => {
    let url = window.location.href.split("#");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start"});
  }, []);

  return (
    <div className="container-fluid">
      <Seo pageContext={pageContext} pageData={pageData} />
      <div id="agence">
      <SectionResultsMapComponent breadcrumbPath={breadcrumbPath} agenciesList={data.cms.agences.data} rechercher={pageContext.region} storeLocator={storeLocator} />
      {
        buildSections(sections, null, null, null, [], 0)
      }
      </div>
    </div>
  )

};

export default Departement;

export const query = graphql`
query departmentAgencesQuery($slugRegion: String, $slugDepartment: String) {
    cms {
        departements(
          filters: {slugDepartement: {eq: $slugDepartment}, and: {slugRegion: {eq: $slugRegion}}},
          pagination: {limit: 1}
        ) {
          data {
            attributes {
              region,
              slugDepartement,
              slugRegion,
              nom
            }
          }
        }
      agences(
        filters: {departements: {slugDepartement: {eq: $slugDepartment}, and: {slugRegion: {eq: $slugRegion}}}}) {
        data {
          attributes {
            latitude
            longitude
            nom
            telephone
            slug
            geojson
          }
        }
      }
      pages(filters: {name: {eq: "storelocator/departement"}}) {
        data {
          attributes {
            sections {
              __typename
              ... on CMS_ComponentStoreLocatorSectionTexteImage {
                id
                bouton {
                  titre
                  style
                }
                slide {
                  id
                  tag
                  texte
                  titre
                }
                image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
              ... on CMS_ComponentStoreLocatorSectionCarteAgences {
                id
              }
            }
            seoMetaDescription
            seoMetaTitle
          }
        }
      }
      storeLocator {
        data {
          attributes {
            horaireApmDebut
            horaireApmFin
            horaireMatinDebut
            horaireMatinFin
          }
        }
      }
    }
  }
`;